import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {DatePipe} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    constructor(private http: HttpClient,
                private datePipe: DatePipe) {
    }

    getCompanyList(pagination) {
        const path = environment.restUrl + `/supervisor/companies`;
        let params = new HttpParams()
            .set('pageSize', String(pagination.pageSize))
            .set('pageNumber', String(pagination.pageIndex))
            .set('orderBy', pagination.orderBy)
            .set('dir', pagination.dir);
        pagination.filters.forEach((element) => {
            const filter = element.property + ':' + element.value + ':' + 'EXACT';
            params = params.append('filter', filter);
        });
        return this.http.get(path, {params: params});
    }

    getCompany(companyId) {
        const path = environment.restUrl + `/supervisor/companies`;
        const params = new HttpParams()
            .set('pageSize', '10')
            .set('pageNumber', '0')
            .set('orderBy', 'name')
            .set('dir', 'asc')
            .set('filter', 'uuid:' + companyId + ':EXACT');
        return this.http.get(path, {params: params}).pipe(
            map((response: any) => response.content[0])
        );
    }


    getCompanies(pageSize: number,
                 pageNumber: number,
                 orderBy: string,
                 dir: string,
                 filters: Array<string>) {
        let params = new HttpParams()
            .set('pageSize', String(pageSize))
            .set('pageNumber', String(pageNumber))
            .set('orderBy', orderBy)
            .set('dir', dir);
        filters.forEach((f) => {
            params = params.append('filter', f);
        });

        const path = environment.restUrl + `/supervisor/companies`;
        return this.http.get(path, {params: params});
    }

    getFeatures() {
        const path = environment.restUrl + '/supervisor/companies/features';
        return this.http.get(path);
    }


    getCompanyFeatures(companyId) {
        const path = environment.restUrl + `/supervisor/companies/${companyId}/features`;
        return this.http.get(path);
    }

    addFeature(companyId, feature) {
        const path = environment.restUrl + `/supervisor/companies/${companyId}/features`;
        const body = {
            type: feature
        };
        return this.http.put(path, body);
    }

    deleteFeature(companyId, feature) {
        const path = environment.restUrl + `/supervisor/companies/${companyId}/features`;
        const body = {
            type: feature
        };
        return this.http.request('delete', path, {body: body});
    }

    getStats(companyId) {
        const path = environment.restUrl + `/supervisor/companies/${companyId}/stats`;
        return this.http.get(path);
    }

    getStatsForCompany(companyId, type) {
        const path = environment.restUrl + `/supervisor/companies/${companyId}/stats/${type}`;
        return this.http.get(path).pipe(
            map((response: any) => {
                return this.convertData(response);
            })
        );
    }

    private convertData(response: any) {
        let series = [];
        response.forEach((value) => {
            const stats = {
                name: this.getDate(value.date),
                value: value.count
            };
            series.push(stats);
        });
        series = series.sort((a, b) => {
            return a.name - b.name;
        });
        return series;
    }

    getDate(value) {
        const year = value.substr(0, 4);
        const month = value.substr(5, 2);
        const day = value.substr(8, 2);
        return new Date(year, month, day);
    }

    getBillingHistory(companyId) {
        const path = environment.restUrl + `/supervisor/companies/${companyId}/billingHistory`;
        return this.http.get(path).pipe(
            map((billings: any) => {
                const stats = [];
                billings.forEach((billing) => {
                    const stat = {
                        name: this.datePipe.transform(billing.created, 'yyyy-MM-dd hh:mm:ss'),
                        value: billing.usersLimit
                    };
                    stats.push(stat);
                });
                return stats;
            })
        );
    }
}
