import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    currentCompany;
    currentPricing;

    constructor() {
    }
}
