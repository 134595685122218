export const PATTERNS = {
    password: '^(?=.*\\d)(?=.*[A-Z])(?=.*[!@#$%^&*?\\|:\";\'<>?,./\(\){}])^(?=.*[a-z]).{8,}$'
};

export const DIALOG_CONFIG = {
    height: 'auto',
    margin: '0 auto',
    width: '60%',
    minWidth: '300px',
    maxWidth: '600px',
    data: null
};

export const TABLE_PAGE_SIZE_OPTIONS = [5, 10, 25, 100];
