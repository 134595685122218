import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {Billing} from './billing.model';
import {Pagination} from '../../../../shared/model/pagination.interface';

export enum BillingActionTypes {
    LoadBillings = '[Billing] Load Billings',
    LoadBillingsSuccess = '[Billing] Load Billings Success',
    LoadBillingsFail = '[Billing] Load Billings Fail',
    AddBilling = '[Billing] Add Billing',
    AddBillingSuccess = '[Billing] Add Billing Success',
    AddBillingFail = '[Billing] Add Billing Fail',
    UpdateBilling = '[Billing] Update Billing',
    UpdateBillingSuccess = '[Billing] Update Billing Success',
    DeleteBilling = '[Billing] Delete Billing',
    DeleteBillingSuccess = '[Billing] Delete Billing Success',
}

export class LoadBillings implements Action {
    readonly type = BillingActionTypes.LoadBillings;

    constructor(public payload: { companyId: string }) {
    }
}

export class LoadBillingsSuccess implements Action {
    readonly type = BillingActionTypes.LoadBillingsSuccess;

    constructor(public payload: { billings: Billing[] }) {
    }
}

export class LoadBillingsFail implements Action {
    readonly type = BillingActionTypes.LoadBillingsFail;
}

export class AddBilling implements Action {
    readonly type = BillingActionTypes.AddBilling;

    constructor(public payload: { month: any, year: any, companyId: string }) {
    }
}

export class AddBillingSuccess implements Action {
    readonly type = BillingActionTypes.AddBillingSuccess;

    constructor(public payload: { billing: Billing }) {
    }
}

export class AddBillingFail implements Action {
    readonly type = BillingActionTypes.AddBillingFail;
}

export class UpdateBilling implements Action {
    readonly type = BillingActionTypes.UpdateBilling;

    constructor(public payload: { billing: Billing }) {
    }
}

export class UpdateBillingSuccess implements Action {
    readonly type = BillingActionTypes.UpdateBillingSuccess;

    constructor(public payload: { billing: Update<Billing> }) {
    }
}

export class DeleteBilling implements Action {
    readonly type = BillingActionTypes.DeleteBilling;

    constructor(public payload: { id: string }) {
    }
}

export class DeleteBillingSuccess implements Action {
    readonly type = BillingActionTypes.DeleteBillingSuccess;

    constructor(public payload: { id: string }) {
    }
}

export type BillingActions =
    LoadBillings
    | LoadBillingsSuccess
    | LoadBillingsFail
    | AddBilling
    | AddBillingSuccess
    | UpdateBilling
    | UpdateBillingSuccess
    | DeleteBilling
    | DeleteBillingSuccess;
