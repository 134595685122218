import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {User} from './user.model';
import {Pagination} from '../../../../shared/model/pagination.interface';

export enum UserActionTypes {
    LoadUsers = '[User] Load Users',
    LoadUsersSuccess = '[User] Load Users Success',
    LoadUsersFail = '[User] Load Users Fail',
    UpdateUser = '[User] Update User',
    UpdateUserSuccess = '[User] Update User Success',
    DeleteUser = '[User] Delete User',
    DeleteUserSuccess = '[User] Delete User Success',
}

export class LoadUsers implements Action {
    readonly type = UserActionTypes.LoadUsers;

    constructor(public payload: { pagination: Pagination }) {
    }
}

export class LoadUsersSuccess implements Action {
    readonly type = UserActionTypes.LoadUsersSuccess;

    constructor(public payload: {
        users: User[],
        pagination: Pagination,
        count: number
    }) {
    }
}

export class LoadUsersFail implements Action {
    readonly type = UserActionTypes.LoadUsersFail;
}

export class UpdateUser implements Action {
    readonly type = UserActionTypes.UpdateUser;

    constructor(public payload: { user: User }) {
    }
}

export class UpdateUserSuccess implements Action {
    readonly type = UserActionTypes.UpdateUserSuccess;

    constructor(public payload: { user: Update<User> }) {
    }
}

export class DeleteUser implements Action {
    readonly type = UserActionTypes.DeleteUser;

    constructor(public payload: { id: string }) {
    }
}

export class DeleteUserSuccess implements Action {
    readonly type = UserActionTypes.DeleteUserSuccess;

    constructor(public payload: { id: string }) {
    }
}

export type UserActions =
    LoadUsers
    | LoadUsersSuccess
    | LoadUsersFail
    | UpdateUser
    | UpdateUserSuccess
    | DeleteUser
    | DeleteUserSuccess;
